import { Login } from '@/screens/login'
import { GetServerSideProps } from 'next'
import { util } from 'node-forge'
import process from 'process'
import { LoginCommonProps } from '@repo/account/types/login-common-props'

export const getServerSideProps: GetServerSideProps<
  LoginCommonProps
> = async () => {
  return {
    props: {
      pemKey: util.decode64(process.env.WEB_PUBLIC_KEY),
    },
  }
}

export default Login
